import React, { useState, useEffect } from 'react';
import { 
  Typography, Box, Select, MenuItem, Button, Paper, 
  CircularProgress, Snackbar, Alert
} from '@mui/material';
import { getSymbols, AdvancedAnalyzeArticles } from '../api'; 

const AIAnalysis = () => {
  const [symbols, setSymbols] = useState([]);
  const [selectedSymbolAdvanced, setSelectedSymbolAdvanced] = useState('');
  const [isAdvancedAnalyzing, setIsAdvancedAnalyzing] = useState(false);
  const [snackbaradvanced, setSnackbarAdvanced] = useState({ open: false, message: '', severity: 'info' });

  useEffect(() => {
    fetchSymbols();
  }, []);

  const fetchSymbols = async () => {
    try {
      const data = await getSymbols();
      setSymbols(data);
    } catch (error) {
      console.error('Error fetching symbols:', error);
      setSnackbarAdvanced({ open: true, message: 'Error fetching symbols', severity: 'error' });
    }
  };

  const handleAdvancedSymbolChange = (event) => {
    setSelectedSymbolAdvanced(event.target.value);
  };

  const handleAdvancedAnalyze = async () => {
    if (!selectedSymbolAdvanced) {
        setSnackbarAdvanced({ open: true, message: 'Please select a symbol', severity: 'warning' });
      return;
    }

    setIsAdvancedAnalyzing(true);
    try {
      const response = await AdvancedAnalyzeArticles(selectedSymbolAdvanced);
      setSnackbarAdvanced({ open: true, message: response.message, severity: 'success' });
    } catch (error) {
      console.error('Error during AI analysis:', error);
      setSnackbarAdvanced({ open: true, message: 'Error during AI analysis', severity: 'error' });
    } finally {
        setIsAdvancedAnalyzing(false);
    }
  };

  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h4" component="h2" gutterBottom>
        AI Analysis
      </Typography>

      <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
        <Typography variant="h6" gutterBottom>
            Adavanced AI Analyze Articles for Symbol
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
          <Select
            value={selectedSymbolAdvanced}
            onChange={handleAdvancedSymbolChange}
            displayEmpty
            sx={{ minWidth: 120 }}
          >
            <MenuItem value="" disabled>Select symbol</MenuItem>
            {symbols.map((symbol) => (
              <MenuItem key={symbol} value={symbol}>{symbol}</MenuItem>
            ))}
          </Select>
          <Button 
            variant="contained" 
            onClick={handleAdvancedAnalyze}
            disabled={isAdvancedAnalyzing || !selectedSymbolAdvanced}
          >
            {isAdvancedAnalyzing ? <CircularProgress size={24} /> : 'Start Analysis'}
          </Button>
        </Box>
        <Typography variant="body2" color="text.secondary">
          This will initiate Advanced AI analysis with ChatGPT for all articles related to the selected symbol.
        </Typography>
      </Paper>

      <Snackbar 
        open={snackbaradvanced.open} 
        autoHideDuration={6000} 
        onClose={() => setSnackbarAdvanced({ ...snackbaradvanced, open: false })}
      >
        <Alert onClose={() => setSnackbarAdvanced({ ...snackbaradvanced, open: false })} severity={snackbaradvanced.severity} sx={{ width: '100%' }}>
          {snackbaradvanced.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AIAnalysis;