import React from 'react';
import { Typography, Box } from '@mui/material';

const Home = () => (
  <Box sx={{ textAlign: 'center', mt: 10 }}>
    <Typography variant="h2" component="h1" gutterBottom>
      Welcome to Stock Analyzer
    </Typography>
    <Typography variant="h5" component="h2" gutterBottom>
      Analyze stocks and track processes with ease
    </Typography>
    <Typography variant="body1" sx={{ mt: 4 }}>
      Use the sidebar menu to navigate through different features of the application.
    </Typography>
  </Box>
);

export default Home;