import React from 'react';
import { Paper, Typography, Box, Grid } from '@mui/material';

const SentimentBox = ({ title, value }) => (
  <Box mb={1}>
    <Typography variant="body2">{title}:</Typography>
    <Typography variant="body1" fontWeight="bold">
      {value !== null ? value.toFixed(2) : 'N/A'}
    </Typography>
  </Box>
);

const ArticlesSentimentAveragesPanel = ({ averages }) => {
  if (!averages) return null;

  const timeframes = [
    { days: 7, key: 'seven_days' },
    { days: 30, key: 'thirty_days' },
    { days: 60, key: 'sixty_days' },
  ];

  return (
    <Paper elevation={3} sx={{ p: 2, height: '100%', mt: 2 }}>
      <Typography variant="h6" gutterBottom>Sentiment Averages</Typography>
      <Grid container spacing={2}>
        {timeframes.map(({ days, key }) => (
          <Grid item xs={12} sm={4} key={key}>
            <Typography variant="subtitle2" gutterBottom>{days} Days Average:</Typography>
            <SentimentBox 
              title="Category Sentiment" 
              value={averages[`${key}_average_category_sentiment`]} 
            />
            <SentimentBox 
              title="AI Category Sentiment" 
              value={averages[`${key}_average_ai_category_sentiment`]} 
            />
            <SentimentBox 
              title="Advanced Sentiment" 
              value={averages[`${key}_average_advanced_sentiment`]} 
            />
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
};

export default ArticlesSentimentAveragesPanel;