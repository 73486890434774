import React, { useState, useEffect, useCallback } from 'react';
import { TextField, Button, Box, Typography, Alert, Autocomplete, Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { addTicker, getTickers, scrapeAllArticles, getSectors } from '../api';
import { useAuthenticatedApi } from '../contexts/AuthContext';

const AddTicker = () => {
  const { authenticatedFetch } = useAuthenticatedApi();
  const [symbol, setSymbol] = useState('');
  const [stockName, setStockName] = useState('');
  const [stockUrl, setStockUrl] = useState('');
  const [sector, setSector] = useState('');
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [sectorsOptions, setSectorsOptions] = useState([]);
  const [tickers, setTickers] = useState([]);
  const [selectedTickers, setSelectedTickers] = useState([]);

  const fetchStockData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await authenticatedFetch(getTickers);
      setTickers(response);
    } catch (error) {
      setMessage({ type: 'error', text: 'Error fetching tickers: ' + error.message });
    } finally {
      setLoading(false);
    }
  }, [authenticatedFetch]);

  const fetchSectorsData = useCallback(async () => {
    try {
      const response = await authenticatedFetch(getSectors);
      setSectorsOptions(response);
    } catch (error) {
      setMessage({ type: 'error', text: 'Error fetching sectors: ' + error.message });
    }
  }, [authenticatedFetch]);

  useEffect(() => {
    fetchStockData();
    fetchSectorsData();
  }, [fetchStockData, fetchSectorsData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage(null);
    try {
      setLoading(true);
      const response = await authenticatedFetch(addTicker, { 
        symbol, 
        stock_name: stockName, 
        stock_url: stockUrl, 
        sector: sector 
      });
      
      setMessage({ type: 'success', text: `Added ticker: ${response.symbol}` });
      setSymbol('');
      setStockName('');
      setStockUrl('');
      setSector('');
      setTickers(prevTickers => [...prevTickers, response]);
    } catch (error) {
      setMessage({ type: 'error', text: 'Error adding ticker: ' + error.message });
    } finally {
      setLoading(false);
    }
  };

  const handleScrapeSelected = async () => {
    if (selectedTickers.length === 0) {
      setMessage({ type: 'error', text: 'Please select at least one ticker to scrape.' });
      return;
    }

    try {
      setLoading(true);
      setMessage(null);
      await authenticatedFetch(scrapeAllArticles, {
        symbols: selectedTickers.map((t) => t.symbol)
      });
      setMessage({ type: 'success', text: 'Successfully started scraping for selected tickers.' });
    } catch (error) {
      setMessage({ type: 'error', text: 'Error scraping tickers: ' + error.message });
    } finally {
      setLoading(false);
    }
  };

  const handleSelectTicker = (ticker) => {
    setSelectedTickers((prevSelected) =>
      prevSelected.includes(ticker)
        ? prevSelected.filter((t) => t !== ticker)
        : [...prevSelected, ticker]
    );
  };

  return (
    <Box sx={{ maxWidth: 800, mx: 'auto', mt: 8 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Add Ticker
      </Typography>
      
      {message && (
        <Alert 
          severity={message.type} 
          sx={{ mb: 2 }}
          onClose={() => setMessage(null)}
        >
          {message.text}
        </Alert>
      )}
      
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          label="Symbol"
          value={symbol}
          onChange={(e) => setSymbol(e.target.value)}
          margin="normal"
          required
          disabled={loading}
        />
        <TextField
          fullWidth
          label="Stock Name"
          value={stockName}
          onChange={(e) => setStockName(e.target.value)}
          margin="normal"
          required
          disabled={loading}
        />
        <TextField
          fullWidth
          label="Stock URL"
          value={stockUrl}
          onChange={(e) => setStockUrl(e.target.value)}
          margin="normal"
          required
          disabled={loading}
        />
        <Autocomplete
          fullWidth
          options={sectorsOptions}
          getOptionLabel={(option) => option || ''} // Handle null/undefined
          renderInput={(params) => (
            <TextField {...params} label="Sector" required />
          )}
          onChange={(event, newValue) => setSector(newValue || '')} // Handle null
          value={sector || null} // Important: use null instead of empty string
          sx={{ mb: 2 }}
          disabled={loading}
          isOptionEqualToValue={(option, value) => option === value} // Add this
        />
        <Button 
          type="submit" 
          variant="contained" 
          color="primary" 
          sx={{ mr: 2, mt: 2 }}
          disabled={loading}
        >
          {loading ? 'Adding...' : 'Add Ticker'}
        </Button>
      </form>

      <Box sx={{ mt: 5 }}>
        <Typography variant="h5" component="h2" gutterBottom>
          Existing Tickers
        </Typography>
        
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Select</TableCell>
                <TableCell>Symbol</TableCell>
                <TableCell>Stock Name</TableCell>
                <TableCell>Sector</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tickers.map((ticker) => (
                <TableRow key={ticker.symbol}>
                  <TableCell>
                    <Checkbox
                      checked={selectedTickers.includes(ticker)}
                      onChange={() => handleSelectTicker(ticker)}
                      disabled={loading}
                    />
                  </TableCell>
                  <TableCell>{ticker.symbol}</TableCell>
                  <TableCell>{ticker.name}</TableCell>
                  <TableCell>{ticker.sector}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        
        <Button
          onClick={handleScrapeSelected}
          variant="contained"
          color="secondary"
          sx={{ mt: 3 }}
          disabled={loading || selectedTickers.length === 0}
        >
          {loading ? 'Scraping...' : 'Run Scraping for Selected'}
        </Button>
      </Box>
    </Box>
  );
};

export default AddTicker;