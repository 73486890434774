import React, { useState, useEffect } from 'react';
import {
  Typography, Box, TextField, Button, MenuItem, Grid, Paper,
  Stepper, Step, StepLabel, StepContent, Alert, Autocomplete, CircularProgress,
} from '@mui/material';
import { getSymbols, insertReportManualy } from '../api';

const sections = [
  {
    name: 'General Information',
    fields: [
      { name: 'symbol', label: 'Company Symbol', type: 'symbol' },
      { name: 'year', label: 'Year', type: 'number' },
      { name: 'quarter', label: 'Quarter', type: 'select', options: [1, 2, 3, 4] }
    ]
  },
  {
    name: 'Income Statement',
    fields: [
      { name: 'revenue', label: 'Revenue', type: 'number' },
      { name: 'gross_profit', label: 'Gross Profit', type: 'number' },
      { name: 'operating_income', label: 'Operating Income', type: 'number' },
      { name: 'net_profit', label: 'Net Profit', type: 'number' }
    ]
  },
  {
    name: 'Balance Sheet',
    fields: [
      { name: 'total_assets', label: 'Total Assets', type: 'number' },
      { name: 'total_liabilities', label: 'Total Liabilities', type: 'number' },
      { name: 'total_shareholders_equity', label: 'Total Shareholders Equity', type: 'number' },
      { name: 'total_current_assets', label: 'Total Current Assets', type: 'number' },
      { name: 'total_current_liabilities', label: 'Total Current Liabilities', type: 'number' },
    ]
  },
  {
    name: 'Cash Flow',
    fields: [
      { name: 'operating_cash_flow', label: 'Operating Cash Flow', type: 'number' },
      { name: 'investing_cash_flow', label: 'Investing Cash Flow', type: 'number' },
      { name: 'financing_cash_flow', label: 'Financing Cash Flow', type: 'number' }
    ]
  }
];

const ManualReportEntryPage = () => {
  const [activeStep, setActiveStep] = useState(0);
  const currentYear = parseInt(new Date().getFullYear());
  const [formData, setFormData] = useState({ year: currentYear });
  const [errors, setErrors] = useState({});
  const [symbols, setSymbols] = useState([]);
  const [symbolsLoading, setSymbolsLoading] = useState(true);
  const [symbolsError, setSymbolsError] = useState(null);

  useEffect(() => {
    const fetchSymbols = async () => {
      try {
        setSymbolsLoading(true);
        const data = await getSymbols();
        setSymbols(data);
        setSymbolsError(null);
      } catch (error) {
        console.error('Error fetching symbols:', error);
        setSymbolsError('Failed to fetch company symbols. Please try again.');
      } finally {
        setSymbolsLoading(false);
      }
    };

    fetchSymbols();
  }, []);

  const handleNext = () => {
    if (validateSection(sections[activeStep])) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const floatValue = parseFloat(value);
    setFormData({ ...formData, [name]: floatValue });
    setErrors({ ...errors, [name]: '' });
  };

  const handleYearChange = (event) => {
    const year = parseInt(event.target.value);
    setFormData({ ...formData, year });
    setErrors({ ...errors, year: '' });
    };

  const validateSection = (section) => {
    const newErrors = {};
    section.fields.forEach(field => {
      if (!formData[field.name]) {
        newErrors[field.name] = 'This field is required';
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (validateSection(sections[activeStep])) {
        try{
            await insertReportManualy({...formData});
            alert('Report inserted successfully');
        }
        catch (error) {
            console.error('Error inserting report:', error);
            alert(`Error inserting report: ${error.response?.data?.detail || error.message}`);
        }
    }
  };

  const renderField = (field) => {
    switch (field.type) {
      case 'symbol':
        return (
          <Autocomplete
            options={symbols}
            loading={symbolsLoading}
            value={formData[field.name] || null}
            onChange={(event, newValue) => {
              setFormData({ ...formData, [field.name]: newValue });
              setErrors({ ...errors, [field.name]: '' });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={field.label}
                error={!!errors[field.name]}
                helperText={errors[field.name]}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {symbolsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        );
      case 'select':
        return (
          <TextField
            select
            fullWidth
            name={field.name}
            label={field.label}
            value={formData[field.name] || ''}
            onChange={handleInputChange}
            error={!!errors[field.name]}
            helperText={errors[field.name]}
          >
            {field.options.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        );
      case 'number':
        if (field.name === 'year'){
            return (
                <TextField
                    fullWidth
                    name={field.name}
                    label={field.label}
                    type="number"
                    value={formData[field.name] || currentYear}
                    onChange={handleYearChange}
                    error={!!errors[field.name]}
                    helperText={errors[field.name]}
                />
                );
            }
        else {
            return (
                <TextField
                  fullWidth
                  name={field.name}
                  label={field.label}
                  type={field.type}
                  value={formData[field.name] || ''}
                  onChange={handleInputChange}
                  error={!!errors[field.name]}
                  helperText={errors[field.name]}
                />
              );
        };
      default:
        return (
          <TextField
            fullWidth
            name={field.name}
            label={field.label}
            type={field.type}
            value={formData[field.name] || ''}
            onChange={handleInputChange}
            error={!!errors[field.name]}
            helperText={errors[field.name]}
          />
        );
    }
  };

  return (
    <Box sx={{ maxWidth: 600, margin: 'auto', mt: 4 }}>
    <Typography variant="h4" gutterBottom>
      Manual Financial Report Entry
    </Typography>
    {symbolsError && (
      <Alert severity="error" sx={{ mb: 2 }}>
        {symbolsError}
      </Alert>
    )}
    <Paper elevation={3} sx={{ p: 3 }}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {sections.map((section, index) => (
          <Step key={section.name}>
            <StepLabel>{section.name}</StepLabel>
            <StepContent>
              <Grid container spacing={2}>
                {section.fields.map((field) => (
                  <Grid item xs={12} key={field.name}>
                    {renderField(field)}
                  </Grid>
                ))}
              </Grid>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={index === sections.length - 1 ? handleSubmit : handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {index === sections.length - 1 ? 'Submit' : 'Continue'}
                  </Button>
                  <Button
                    disabled={index === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Back
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === sections.length && (
        <Alert severity="success" sx={{ mt: 2 }}>
          All steps completed - you&apos;re finished
        </Alert>
      )}
    </Paper>
  </Box>
);
};

export default ManualReportEntryPage;