import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Autocomplete,
  Paper,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { getSymbols, parsePDF } from '../api';

const Input = styled('input')({
  display: 'none',
});

const QuarterlyReportUpload = () => {
  const [symbols, setSymbols] = useState([]);
  const [selectedSymbol, setSelectedSymbol] = useState('');
  const [year, setYear] = useState(parseInt(new Date().getFullYear()));
  const [quarter, setQuarter] = useState('');
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');


  useEffect(() => {
    fetchSymbols();
  }, []);
  const fetchSymbols = async () => {
    try {
      const data = await getSymbols();
      setSymbols(data);
    } catch (error) {
      console.error('Error fetching symbols:', error);
    }
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    setFileName(event.target.files[0].name);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!selectedSymbol || !year || !quarter || !file) {
      alert('Please fill all fields and select a file');
      return;
    }
    try {
      // Create a FormData object
      const formData = new FormData();
      formData.append('file', file); // Append the file
      formData.append('symbol', selectedSymbol); // Append other form data
      formData.append('year', year);
      formData.append('quarter', quarter);
  
      // Send the FormData to the backend
      await parsePDF(formData);
  
      alert('Report parsing finished successfully');
    } catch (error) {
      console.error('Error parsing report:', error);
      alert(`Error parsing report: ${error.response?.data?.detail || error.message}`);
    }
  };
  

  return (
    <Box sx={{ maxWidth: 600, margin: 'auto', mt: 4 }}>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Upload Quarterly Report
        </Typography>
        <form onSubmit={handleSubmit}>
          <Autocomplete
            options={symbols}
            renderInput={(params) => <TextField {...params} label="Select Symbol" />}
            value={selectedSymbol}
            onChange={(event, newValue) => setSelectedSymbol(newValue)}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="Year"
            type="number"
            value={year}
            onChange={(e) => setYear(e.target.value)}
            sx={{ mb: 2 }}
          />
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Quarter</InputLabel>
            <Select
              value={quarter}
              label="Quarter"
              onChange={(e) => setQuarter(e.target.value)}
            >
              <MenuItem value={1}>Q1</MenuItem>
              <MenuItem value={2}>Q2</MenuItem>
              <MenuItem value={3}>Q3</MenuItem>
            </Select>
          </FormControl>
          <Box sx={{ mb: 2 }}>
            <label htmlFor="contained-button-file">
              <Input
                accept="application/pdf"
                id="contained-button-file"
                type="file"
                onChange={handleFileChange}
              />
              <Button variant="contained" component="span">
                Choose PDF File
              </Button>
            </label>
            {fileName && (
              <Typography variant="body2" sx={{ mt: 1 }}>
                Selected file: {fileName}
              </Typography>
            )}
          </Box>
          <Button type="submit" variant="contained" color="primary">
            Upload and Parse Report
          </Button>
        </form>
      </Paper>
    </Box>
  );
};

export default QuarterlyReportUpload;