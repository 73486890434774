import React, { useState, useEffect } from 'react';
import {
  Typography, Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  Dialog, DialogTitle, DialogContent, DialogActions, TextField, Chip, IconButton, Snackbar, Alert
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon } from '@mui/icons-material';
import { getCategories, addCategory, updateCategory } from '../api';

const CategoriesManagement = () => {
  const [categories, setCategories] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentCategory, setCurrentCategory] = useState({ id: null, name: '', keywords: [], sentiment: 0 });
  const [newKeyword, setNewKeyword] = useState('');
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const data = await getCategories();
      setCategories(data);
    } catch (error) {
      console.error('Error fetching categories:', error);
      setSnackbar({ open: true, message: 'Error fetching categories', severity: 'error' });
    }
  };

  const handleOpenDialog = (category = { id: null, name: '', keywords: [], sentiment: 0 }) => {
    setCurrentCategory(category);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setCurrentCategory({ id: null, name: '', keywords: [], sentiment: 0 });
    setNewKeyword('');
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCurrentCategory({ ...currentCategory, [name]: value });
  };

  const handleAddKeyword = () => {
    if (newKeyword && !currentCategory.keywords.includes(newKeyword)) {
      setCurrentCategory({
        ...currentCategory,
        keywords: [...currentCategory.keywords, newKeyword]
      });
      setNewKeyword('');
    }
  };

  const handleRemoveKeyword = (keywordToRemove) => {
    setCurrentCategory({
      ...currentCategory,
      keywords: currentCategory.keywords.filter(keyword => keyword !== keywordToRemove)
    });
  };

  const handleSaveCategory = async () => {
    try {
      if (currentCategory.id) {
        await updateCategory({
          id: currentCategory.id,  // This should match the 'category_id' expected by the backend
          name: currentCategory.name,
          keywords: currentCategory.keywords,
          sentiment: parseFloat(currentCategory.sentiment)  // Ensure this is a number
        });
        setSnackbar({ open: true, message: 'Category updated successfully', severity: 'success' });
      } else {
        await addCategory(currentCategory);
        setSnackbar({ open: true, message: 'Category added successfully', severity: 'success' });
      }
      fetchCategories();
      handleCloseDialog();
    } catch (error) {
      console.error('Error saving category:', error);
      setSnackbar({ open: true, message: 'Error saving category', severity: 'error' });
    }
  };

  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h4" component="h2" gutterBottom>
        Categories Management
      </Typography>
      <Button
        variant="contained"
        startIcon={<AddIcon />}
        onClick={() => handleOpenDialog()}
        sx={{ mb: 2 }}
      >
        Add New Category
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Keywords</TableCell>
              <TableCell>Sentiment</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {categories.map((category) => (
              <TableRow key={category.id}>
                <TableCell>{category.name}</TableCell>
                <TableCell>
                  {category.keywords.map((keyword, index) => (
                    <Chip key={index} label={keyword} sx={{ mr: 0.5, mb: 0.5 }} />
                  ))}
                </TableCell>
                <TableCell>{category.sentiment.toFixed(2)}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleOpenDialog(category)}>
                    <EditIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
        <DialogTitle>{currentCategory.id ? 'Edit Category' : 'Add New Category'}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="name"
            label="Category Name"
            type="text"
            fullWidth
            value={currentCategory.name}
            onChange={handleInputChange}
          />
          <Box sx={{ mt: 2, mb: 1 }}>
            <Typography variant="subtitle1">Keywords</Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {currentCategory.keywords.map((keyword, index) => (
                <Chip
                  key={index}
                  label={keyword}
                  onDelete={() => handleRemoveKeyword(keyword)}
                />
              ))}
            </Box>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
            <TextField
              margin="dense"
              label="Add Keyword"
              type="text"
              value={newKeyword}
              onChange={(e) => setNewKeyword(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  handleAddKeyword();
                }
              }}
            />
            <Button onClick={handleAddKeyword} sx={{ ml: 1 }}>
              Add
            </Button>
          </Box>
          <TextField
            margin="dense"
            name="sentiment"
            label="Sentiment"
            type="number"
            fullWidth
            value={currentCategory.sentiment}
            onChange={handleInputChange}
            inputProps={{ step: 0.01, min: -1, max: 1 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleSaveCategory}>Save</Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default CategoriesManagement;