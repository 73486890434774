// src/contexts/AuthContext.js
import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
import { login as apiLogin, getCurrentUser } from '../api';
import { useNavigate } from 'react-router-dom'; 
import axios from 'axios';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [authToken, setAuthToken] = useState(localStorage.getItem('token')); // Rename token to authToken
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const initializeAuth = async () => {
      const storedToken = localStorage.getItem('token');
      if (storedToken) {
        try {
          // Add token to axios headers immediately
          axios.defaults.headers.common['Authorization'] = `Bearer ${storedToken}`;
          const userData = await getCurrentUser();
          setUser(userData);
          setAuthToken(storedToken);
        } catch (error) {
          console.error('Token validation failed:', error);
          localStorage.removeItem('token');
          setAuthToken(null);
          setUser(null);
        }
      }
      setLoading(false);
    };
  
    initializeAuth();
  }, []);

  const login = useCallback(async (username, password) => {
    try {
      setError(null);
      const data = await apiLogin(username, password);
      
      localStorage.setItem('token', data.access_token);
      setAuthToken(data.access_token);
      
      const userData = await getCurrentUser();
      setUser(userData);

      return true;
    } catch (error) {
      setError(error.response?.data?.detail || 'Login failed');
      console.error('Login error:', error);
      return false;
    }
  }, []);

  const logout = useCallback(() => {
    localStorage.removeItem('token');
    setAuthToken(null);
    setUser(null);
    setError(null);
  }, []);

  const refreshUser = useCallback(async () => {
    if (!authToken) return; // Use authToken instead of token
    
    try {
      const userData = await getCurrentUser();
      setUser(userData);
    } catch (error) {
      console.error('Failed to refresh user data:', error);
    }
  }, [authToken]); // Update dependency

  const clearError = useCallback(() => {
    setError(null);
  }, []);

  const value = {
    token: authToken, // Keep the external API as 'token'
    user,
    login,
    logout,
    loading,
    error,
    clearError,
    refreshUser,
    isAuthenticated: !!authToken && !!user,
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const useAuthenticatedApi = () => {
    const { logout } = useAuth();
    const navigate = useNavigate(); // Add this
    
    const authenticatedFetch = useCallback(async (apiCall, ...args) => {
      try {
        return await apiCall(...args);
      } catch (error) {
        if (error.response?.status === 401) {
          await logout();
          navigate('/login'); // Use React Router's navigate
          throw new Error('Session expired. Please login again.');
        }
        throw error;
      }
    }, [logout, navigate]);
  
    return { authenticatedFetch };
  };