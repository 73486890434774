import React, { useState, useEffect, useCallback } from 'react';
import {
  Typography, Box, TextField, Button, Grid,
  Dialog, DialogActions, DialogContent, DialogTitle,
  Divider, List, ListItem, ListItemText, Select, MenuItem
} from '@mui/material';
import { getReportById, updateReport } from '../api';

const FieldGroup = ({ title, fields, report, handleInputChange }) => (
  <Box sx={{ mb: 3 }}>
    <Typography variant="h6" gutterBottom>{title}</Typography>
    <Grid container spacing={2}>
      {fields.map((field) => (
        <Grid item xs={12} sm={6} key={field.name}>
          {field.name === "amount_type" ? (
            <Select
              fullWidth
              label={field.label}
              name={field.name}
              value={report[field.name] || ''}
              onChange={handleInputChange}
              variant="outlined"
              sx={{ mt: 2, mb: 1 }}  // Adjust these values as needed
            >
              <MenuItem value="Millions">Millions</MenuItem>
              <MenuItem value="Thousands">Thousands</MenuItem>
            </Select>
          ) : (
            <TextField
              fullWidth
              label={field.label}
              name={field.name}
              type={field.type || "number"}
              value={report[field.name] ?? ''}
              onChange={handleInputChange}
              variant="outlined"
              margin="normal"
              InputProps={field.inputProps}
              disabled={field.disabled}
            />
          )}
        </Grid>
      ))}
    </Grid>
  </Box>
);

const ReportEditor = ({ reportId, open, onClose, onSave }) => {
  const [report, setReport] = useState(null);
  const [originalReport, setOriginalReport] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchReport = useCallback(async () => {
    setLoading(true);
    try {
      const data = await getReportById(reportId);
      setReport(data);
      setOriginalReport(data);
      setError(null);
    } catch (err) {
      setError('Failed to fetch report data');
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [reportId]);

  useEffect(() => {
    if (reportId && open) {
      fetchReport();
    }
  }, [reportId, open, fetchReport]);

  const calculateMargin = useCallback((cashFlow, revenue) => {
    if (cashFlow == null || revenue == null || revenue === 0) return null;
    return Number((cashFlow / revenue * 100).toFixed(2));
  }, []);

  const calculateRatio = useCallback((value1, value2) => {
    if (value1 == null || value2 == null || value2 === 0) return null;
    return Number((value1 / value2).toFixed(2));
  }, []);

  const handleInputChange = useCallback((event) => {
    const { name, value } = event.target;
    setReport(prev => {
      if (name === "amount_type") {
        return { ...prev, [name]: value };
      }
      const newValue = value === '' ? null : Number(value);
      const updatedReport = { ...prev, [name]: newValue };


        // Calculate income statement ratios
        if (['revenue', 'gross_profit', 'operating_income', 'net_profit'].includes(name)) {
          updatedReport.revenue_to_gross_profit_ratio = calculateMargin(updatedReport.gross_profit, updatedReport.revenue);
          updatedReport.revenue_to_operating_income_ratio = calculateMargin(updatedReport.operating_income, updatedReport.revenue);
          updatedReport.revenue_to_net_profit_ratio = calculateMargin(updatedReport.net_profit, updatedReport.revenue);
        }

        // Calculate balance sheet ratios
        if (['total_assets', 'total_liabilities', 'total_shareholders_equity', 'total_current_assets', 'total_current_liabilities'].includes(name)) {
        updatedReport.total_liabilities_to_equity_ratio = calculateRatio(updatedReport.total_liabilities, updatedReport.total_shareholders_equity);
        updatedReport.leverage_ratio = calculateRatio(updatedReport.total_liabilities, updatedReport.total_assets);
        updatedReport.liquidity_ratio = calculateRatio(updatedReport.total_current_assets, updatedReport.total_current_liabilities);
        }

        // Calculate cash flow margins
        if (['revenue', 'operating_cash_flow', 'investing_cash_flow', 'financing_cash_flow'].includes(name)) {
        updatedReport.cfo_margin = calculateMargin(updatedReport.operating_cash_flow, updatedReport.revenue);
        updatedReport.cfi_margin = calculateMargin(updatedReport.investing_cash_flow, updatedReport.revenue);
        updatedReport.cff_margin = calculateMargin(updatedReport.financing_cash_flow, updatedReport.revenue);
        }

        return updatedReport;
      });
    }, [calculateMargin, calculateRatio]);
  

  const getChangedFields = useCallback(() => {
    if (!report || !originalReport) return {};
    return Object.entries(report).reduce((acc, [key, value]) => {
      if (value !== originalReport[key] && key !== 'symbol' && key !== 'year' && key !== 'quarter') {
        acc[key] = value;
      }
      return acc;
    }, {});
  }, [report, originalReport]);

  const handleSave = async () => {
    try {
    await updateReport({ ...report});
      onSave(report);
      onClose();
    } catch (err) {
      setError('Failed to update report');
      console.error(err);
    }
  };

  if (loading) return <Typography>Loading...</Typography>;
  if (error) return <Typography color="error">{error}</Typography>;
  if (!report) return null;

  const changedFields = getChangedFields();

  const sections = [
    {
      title: "General",
      fields: [
        { name: "symbol", label: "Symbol", type: "text", disabled: true },
        { name: "year", label: "Year", disabled: true },
        { name: "quarter", label: "Quarter", disabled: true },
        { name: "amount_type", label: "Amount Type", disabled: false },
      ]
    },
    {
      title: "Income Statement",
      fields: [
        { name: "revenue", label: "Revenue" },
        { name: "gross_profit", label: "Gross Profit" },
        { name: "operating_income", label: "Operating Income" },
        { name: "net_profit", label: "Net Profit" },
        { name: "revenue_to_gross_profit_ratio", label: "Gross Profit Margin", inputProps: { step: 0.01, min: 0, max: 1 }, disabled: true },
        { name: "revenue_to_operating_income_ratio", label: "Operating Margin", inputProps: { step: 0.01, min: 0, max: 1 }, disabled: true },
        { name: "revenue_to_net_profit_ratio", label: "Net Profit Margin", inputProps: { step: 0.01, min: 0, max: 1 }, disabled: true },
      ]
    },
    {
      title: "Balance Sheet",
      fields: [
        { name: "total_assets", label: "Total Assets" },
        { name: "total_liabilities", label: "Total Liabilities" },
        { name: "total_shareholders_equity", label: "Total Shareholders Equity" },
        { name: "total_current_assets", label: "Total Current Assets" },
        { name: "total_current_liabilities", label: "Total Current Liabilities" },
        { name: "total_liabilities_to_equity_ratio", label: "Liabilities to Equity Ratio", inputProps: { step: 0.01 }, disabled: true },
        { name: "leverage_ratio", label: "Leverage Ratio", inputProps: { step: 0.01 } , disabled: true},
        { name: "liquidity_ratio", label: "Liquidity Ratio", inputProps: { step: 0.01 } , disabled: true},
      ]
    },
    {
      title: "Cash Flow",
      fields: [
        { name: "operating_cash_flow", label: "Operating Cash Flow" },
        { name: "investing_cash_flow", label: "Investing Cash Flow" },
        { name: "financing_cash_flow", label: "Financing Cash Flow" },
        { name: "cfo_margin", label: "CFO Margin (%)", inputProps: { step: 0.01 }, disabled: true },
        { name: "cfi_margin", label: "CFI Margin (%)", inputProps: { step: 0.01 }, disabled: true },
        { name: "cff_margin", label: "CFF Margin (%)", inputProps: { step: 0.01 }, disabled: true },
      ]
    }
  ];

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Edit Report</DialogTitle>
      <DialogContent>
        {sections.map((section, index) => (
          <React.Fragment key={section.title}>
            <FieldGroup
              title={section.title}
              fields={section.fields}
              report={report}
              handleInputChange={handleInputChange}
            />
            {index < sections.length - 1 && <Divider sx={{ my: 2 }} />}
          </React.Fragment>
        ))}
        {Object.keys(changedFields).length > 0 && (
          <Box sx={{ mt: 2 }}>
            <Typography variant="h6">Changed Fields:</Typography>
            <List>
              {Object.entries(changedFields).map(([field, value]) => (
                <ListItem key={field}>
                  <ListItemText 
                    primary={
                      field === "amount_type" 
                        ? `${field}: ${value}`
                        : `${field}: ${value !== null ? Number(value).toFixed(2) : 'N/A'}`
                    }
                    secondary={
                      field === "amount_type"
                        ? `Original: ${originalReport[field] || 'N/A'}`
                        : `Original: ${originalReport[field] !== null ? Number(originalReport[field]).toFixed(2) : 'N/A'}`
                    }
                  />
                </ListItem>
              ))}
            </List>
          </Box>
    )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave} color="primary" disabled={Object.keys(changedFields).length === 0}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReportEditor;