import React, { useState, useEffect, useCallback } from 'react';
import {
  Typography, Box, TextField, MenuItem, Autocomplete,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  Alert, CircularProgress, Tabs, Tab, Tooltip, ToggleButton, ToggleButtonGroup
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { BarChart, Bar, XAxis, YAxis, Tooltip as RechartsTooltip, ResponsiveContainer, CartesianGrid, Legend, ReferenceLine } from 'recharts';
import { getSymbols, filterReports } from '../api';

const FinancialReportPage = () => {
  const [symbols, setSymbols] = useState([]);
  const [selectedSymbol, setSelectedSymbol] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [sector, setSector] = useState(null);
  const [selectedMetrics, setSelectedMetrics] = useState({});

  useEffect(() => {
    fetchSymbols();
  }, []);

  const fetchSymbols = async () => {
    try {
      const data = await getSymbols();
      setSymbols(data);
    } catch (error) {
      console.error('Error fetching symbols:', error);
      setError('Failed to fetch symbols. Please try again.');
    }
  };

  const fetchReports = useCallback(async () => {
    if (!selectedSymbol) return;

    setLoading(true);
    setError(null);

    try {
      const response = await filterReports({
        symbol: selectedSymbol,
        year: selectedYear,
      });
      setReports(response.items || []);
      setSector(response.sector || null);
    } catch (error) {
      console.error('Error fetching reports:', error);
      setError('Failed to fetch reports. Please try again.');
    } finally {
      setLoading(false);
    }
  }, [selectedSymbol, selectedYear]);

  useEffect(() => {
    if (selectedSymbol) {
      fetchReports();
    }
  }, [selectedSymbol, selectedYear, fetchReports]);

  const handleSymbolChange = (event, newValue) => {
    setSelectedSymbol(newValue);
    setSelectedYear(null);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const formatCurrency = (value) => {
    if (value === null || value === undefined) return 'N/A';
    return `$${value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
  };

  const formatPercentage = (value) => {
    if (value === null || value === undefined) return 'N/A';
    return `${value.toFixed(2)}%`;
  };

  const formatRatio = (value) => {
    if (value === null || value === undefined) return 'N/A';
    return value.toFixed(2);
  };

  const isWithinRange = (value, range) => {
    if (!range || value === null || value === undefined) return false;
    const [min, max] = range.split(' - ').map(Number);
    return min <= max ? (value >= min && value <= max) : (value >= max && value <= min);
  };

  const getColorForValue = (value, columnId) => {
    if (value === null || value === undefined || value === 'N/A') return 'inherit';
    const rangeKey = getSectorRangeKey(columnId);
    if (!sector || !sector[rangeKey]) return 'inherit';
    return isWithinRange(value, sector[rangeKey]) ? 'green' : 'red';
  };

  const getSectorRangeKey = (columnId) => {
    const keyMap = {
      'total_liabilities_to_equity_ratio': 'debt_to_equity_ratio_range',
      'leverage_ratio': 'debt_ratio_range',
      'liquidity_ratio': 'current_ratio_range'
    };
    return keyMap[columnId] || `${columnId}_range`;
  };

  const incomeStatementColumns = [
    { id: 'year', label: 'Year', format: (value) => value },
    { id: 'quarter', label: 'Quarter', format: (value) => value === 0 ? 'Annual' : `Q${value}` },
    { id: 'revenue', label: 'Revenue', format: formatCurrency },
    { id: 'amount_type', label: 'Amount Type', format: (value) => value },
    { id: 'gross_profit', label: 'Gross Profit', format: formatCurrency },
    { id: 'operating_income', label: 'Operating Income', format: formatCurrency },
    { id: 'net_profit', label: 'Net Income', format: formatCurrency },
    { id: 'revenue_to_gross_profit_ratio', label: 'Gross Profit Margin', format: formatPercentage },
    { id: 'revenue_to_operating_income_ratio', label: 'Operating Margin', format: formatPercentage },
    { id: 'revenue_to_net_profit_ratio', label: 'Net Profit Margin', format: formatPercentage },
  ];

  const balanceSheetColumns = [
    { id: 'year', label: 'Year', format: (value) => value },
    { id: 'quarter', label: 'Quarter', format: (value) => value === 0 ? 'Annual' : `Q${value}` },
    { id: 'total_assets', label: 'Total Assets', format: formatCurrency },
    { id: 'total_current_assets', label: 'Total Current Assets', format: formatCurrency },
    { id: 'total_liabilities', label: 'Total Liabilities', format: formatCurrency },
    { id: 'total_current_liabilities', label: 'Total Current Liabilities', format: formatCurrency },
    { id: 'total_shareholders_equity', label: "Total Stockholders' Equity", format: formatCurrency },
    { id: 'total_liabilities_to_equity_ratio', label: 'Liabilities to Equity Ratio', format: formatRatio },
    { id: 'leverage_ratio', label: 'Leverage Ratio', format: formatRatio },
    { id: 'liquidity_ratio', label: 'Liquidity Ratio', format: formatRatio },
  ];

  const cashFlowColumns = [
    { id: 'year', label: 'Year', format: (value) => value },
    { id: 'quarter', label: 'Quarter', format: (value) => value === 0 ? 'Annual' : `Q${value}` },
    { id: 'operating_cash_flow', label: 'Cash Flow from Operating Activities', format: formatCurrency },
    { id: 'investing_cash_flow', label: 'Cash Flow from Investing Activities', format: formatCurrency },
    { id: 'financing_cash_flow', label: 'Cash Flow from Financing Activities', format: formatCurrency },
    { id: 'cfo_margin', label: 'CFO Margin', format: formatPercentage },
    { id: 'cfi_margin', label: 'CFI Margin', format: formatPercentage },
    { id: 'cff_margin', label: 'CFF Margin', format: formatPercentage },
  ];

  const handleMetricToggle = (event, newMetrics) => {
    setSelectedMetrics(prevMetrics => ({
      ...prevMetrics,
      [activeTab]: newMetrics
    }));
  };

  const renderToggleButtons = (columns) => {
    const metrics = columns.filter(col => !['year', 'quarter', 'amount_type'].includes(col.id)).map(col => col.id);
    return (
      <ToggleButtonGroup
        value={selectedMetrics[activeTab] || []}
        onChange={handleMetricToggle}
        aria-label="metric selection"
        sx={{ mb: 2, flexWrap: 'wrap' }}
      >
        {metrics.map((metric) => (
          <ToggleButton key={metric} value={metric} aria-label={metric}>
            {columns.find(col => col.id === metric).label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    );
  };

  const getHealthyRange = (metric) => {
    if (!sector) return null;
    const rangeKey = getSectorRangeKey(metric);
    const range = sector[rangeKey];
    if (!range) return null;
    const [min, max] = range.split(' - ').map(Number);
    return { min, max };
  };

  const renderCharts = (columns, data) => {
    data.sort((a, b) => {
      if (a.year === b.year) {
        return a.quarter - b.quarter;
      }
      return a.year - b.year;
    });

    const chartData = data.map(report => {
      if (report.quarter === 0) {
        return null;
      }
      return {
        name: `Q${report.quarter} ${report.year}`,
                ...columns.reduce((acc, column) => {
          if (!['year', 'quarter'].includes(column.id)) {
            acc[column.id] = report[column.id];
          }
          return acc;
        }, {})
      };
    }).filter(report => report !== null);
  
    const metrics = selectedMetrics[activeTab] || [];
  
    return metrics.map((metric) => {
      const column = columns.find(col => col.id === metric);
      const healthyRange = getHealthyRange(metric);
      
      return (
        <Box key={metric} mb={4}>
          <Typography variant="h6" gutterBottom>{column.label}</Typography>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={chartData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" angle={-45} textAnchor="end" height={70} />
              <YAxis />
              <RechartsTooltip
                content={({ active, payload, label }) => {
                  if (active && payload && payload.length) {
                    const value = payload[0].value;
                    return (
                      <div style={{ backgroundColor: 'white', padding: '10px', border: '1px solid #ccc' }}>
                        <p>{`${label}: ${column.format(value)}`}</p>
                        {healthyRange && (
                          <p style={{ color: value >= healthyRange.min && value <= healthyRange.max ? 'green' : 'red' }}>
                            {`Healthy range: ${column.format(healthyRange.min)} - ${column.format(healthyRange.max)}`}
                          </p>
                        )}
                      </div>
                    );
                  }
                  return null;
                }}
              />
              <Legend />
              <Bar dataKey={metric} fill={`hsl(${Math.random() * 360}, 70%, 50%)`} />
              {healthyRange && (
                <>
                  <ReferenceLine
                    y={healthyRange.min}
                    stroke="green"
                    strokeDasharray="3 3"
                    label={{ value: 'Min', position: 'insideTopLeft', fill: 'green' }}
                  />
                  <ReferenceLine
                    y={healthyRange.max}
                    stroke="green"
                    strokeDasharray="3 3"
                    label={{ value: 'Max', position: 'insideTopLeft', fill: 'green' }}
                  />
                </>
              )}
            </BarChart>
          </ResponsiveContainer>
        </Box>
      );
    });
  };


  const renderTable = (columns) => {
    // Custom sorting function
    const sortReports = (a, b) => {
      if (a.year !== b.year) {
        return b.year - a.year; // Sort by year descending
      }
      if (a.quarter === 0) return -1; // Annual report comes first within the same year
      if (b.quarter === 0) return 1;
      return b.quarter - a.quarter; // Sort quarters descending
    };
  
    // Create a sorted copy of the reports
    const sortedReports = [...reports].sort(sortReports);
  
    return (
      <TableContainer component={Paper} elevation={3}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align="center" sx={{ fontWeight: 'bold' }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedReports.map((report) => (
              <TableRow key={`${report.year}-${report.quarter}`} hover>
                {columns.map((column) => {
                  const value = report[column.id];
                  const color = getColorForValue(value, column.id);
                  const rangeKey = getSectorRangeKey(column.id);
                  const range = sector && sector[rangeKey];
                  return (
                    <TableCell 
                      key={column.id} 
                      align="center"
                      sx={{ color }}
                    >
                      <Tooltip 
                        title={range ? `Healthy range: ${range}` : ''}
                        arrow
                      >
                        <span>{column.format(value)}</span>
                      </Tooltip>
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box sx={{ mt: 4, px: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Financial Report Analysis
        </Typography>
        <Box sx={{ display: 'flex', gap: 2, mb: 4, alignItems: 'center' }}>
          <Autocomplete
            options={symbols}
            value={selectedSymbol}
            onChange={handleSymbolChange}
            renderInput={(params) => <TextField {...params} label="Select Symbol" />}
            sx={{ width: 200 }}
          />
          <TextField
            select
            label="Year"
            value={selectedYear}
            onChange={handleYearChange}
            sx={{ width: 120 }}
            disabled={!selectedSymbol}
          >
            <MenuItem value={null}>All Years</MenuItem>
            {Array.from(new Set(reports.map(r => r.year))).sort((a, b) => b - a).map((year) => (
              <MenuItem key={year} value={year}>{year}</MenuItem>
            ))}
          </TextField>
        </Box>
        
        {sector && (
          <Alert severity="info" sx={{ mb: 2 }}>
            Sector: {sector.name.charAt(0).toUpperCase() + sector.name.slice(1)}
          </Alert>
        )}

        {loading && <CircularProgress />}
        {error && <Alert severity="error">{error}</Alert>}

        {!loading && !error && reports.length === 0 && selectedSymbol && (
          <Alert severity="info">No reports found for the selected criteria.</Alert>
        )}

{!loading && !error && reports.length > 0 && (
          <>
            <Tabs value={activeTab} onChange={(e, newValue) => setActiveTab(newValue)} centered sx={{ mb: 4 }}>
              <Tab label="Income Statement" />
              <Tab label="Balance Sheet" />
              <Tab label="Cash Flow" />
            </Tabs>

            {activeTab === 0 && (
              <>
                <Typography variant="h6" gutterBottom>Income Statement Metrics</Typography>
                {renderToggleButtons(incomeStatementColumns)}
                {renderCharts(incomeStatementColumns, reports)}
                <Box mb={4}>
                  <Typography variant="h6" gutterBottom>Detailed Income Statement</Typography>
                  {renderTable(incomeStatementColumns)}
                </Box>
              </>
            )}

            {activeTab === 1 && (
              <>
                <Typography variant="h6" gutterBottom>Balance Sheet Metrics</Typography>
                {renderToggleButtons(balanceSheetColumns)}
                {renderCharts(balanceSheetColumns, reports)}
                <Box mb={4}>
                  <Typography variant="h6" gutterBottom>Detailed Balance Sheet</Typography>
                  {renderTable(balanceSheetColumns)}
                </Box>
              </>
            )}

            {activeTab === 2 && (
              <>
                <Typography variant="h6" gutterBottom>Cash Flow Metrics</Typography>
                {renderToggleButtons(cashFlowColumns)}
                {renderCharts(cashFlowColumns, reports)}
                <Box mb={4}>
                  <Typography variant="h6" gutterBottom>Detailed Cash Flow Statement</Typography>
                  {renderTable(cashFlowColumns)}
                </Box>
              </>
            )}
          </>
        )}

        {!selectedSymbol && (
          <Alert severity="info" icon={false}>
            Please select a symbol to view financial reports.
          </Alert>
        )}
      </Box>
    </LocalizationProvider>
  );
};

export default FinancialReportPage;