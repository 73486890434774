import React, { useState, useEffect, useCallback } from 'react';
import {
  Typography, Box, TextField, Button, MenuItem,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  TablePagination, Autocomplete, Collapse, IconButton
} from '@mui/material';
import ReportEditor from './ReportEditor';
import { styled } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { getSymbols, filterReports } from '../api';
import { useAuthenticatedApi } from '../contexts/AuthContext';


const ExpandableTableRow = ({ 
  children, 
  expandContent, 
  ...otherProps 
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <>
      <TableRow {...otherProps}>
        <TableCell padding="checkbox">
          <IconButton
            size="small"
            onClick={() => setIsExpanded(!isExpanded)}
          >
            {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {children}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={20}>
          <Collapse in={isExpanded} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              {expandContent}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const DetailCard = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.grey[50],
  borderRadius: theme.shape.borderRadius,
  '& .MuiTypography-subtitle1': {
    marginBottom: theme.spacing(2),
    fontWeight: 600,
  },
}));

const ReportFilter = () => {
  const { authenticatedFetch } = useAuthenticatedApi();
  const [symbols, setSymbols] = useState([]);
  const [selectedSymbol, setSelectedSymbol] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedQuarter, setSelectedQuarter] = useState(null);
  const [reports, setReports] = useState([]);
  const [totalReports, setTotalReports] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [editingReportId, setEditingReportId] = useState(null);
  


  const fetchSymbols = useCallback(async () => {
    try {
      const data = await authenticatedFetch(getSymbols);
      setSymbols(data);
    } catch (error) {
      console.error('Error fetching symbols:', error);
    }
  }, [authenticatedFetch]);
  
  useEffect(() => {
    fetchSymbols();
  }, [fetchSymbols]);
  
  const fetchReports = useCallback(async () => {
    if (!selectedSymbol) return;

    try {
      const response = await authenticatedFetch(filterReports, {
        symbol: selectedSymbol,
        year: selectedYear,
        quarter: selectedQuarter,
        page: page + 1,
        page_size: rowsPerPage
      });
      setReports(response.items || []);
      setTotalReports(response.total || 0);
    } catch (error) {
      console.error('Error fetching reports:', error);
    }
  }, [selectedSymbol, selectedYear, selectedQuarter, page, rowsPerPage, authenticatedFetch]);

  useEffect(() => {
    fetchReports();
  }, [fetchReports]);

  const handleSymbolChange = (event, newValue) => {
    setSelectedSymbol(newValue);
    setPage(0);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleQuarterChange = (event) => {
    setSelectedQuarter(event.target.value);
  };

  const handleFilter = () => {
    setPage(0);
    fetchReports();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const formatCurrency = (value) => value !== null ? `$${value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : 'N/A';
  const formatPercentage = (value) => value !== null ? `${value}%` : 'N/A';
  const formatRatio = (value) => value !== null ? value.toFixed(2) : 'N/A';

  const summaryColumns = [
    { id: 'symbol', label: 'Symbol', format: (value) => value },
    { id: 'year', label: 'Year', format: (value) => value },
    { id: 'quarter', label: 'Quarter', format: (value) => `Q${value}` },
  ];

  const detailGroups = {
    IncomeStatement: [
      { id: 'revenue', label: 'Revenue', format: formatCurrency },
      { id: 'net_profit', label: 'Net Profit', format: formatCurrency },
      { id: 'gross_profit', label: 'Gross Profit', format: formatCurrency },
      { id: 'operating_income', label: 'Operating Income', format: formatCurrency },
      { id: 'revenue_to_net_profit_ratio', label: 'Net Profit Margin', format: formatPercentage },
      { id: 'revenue_to_gross_profit_ratio', label: 'Gross Profit Margin', format: formatPercentage },
      { id: 'revenue_to_operating_income_ratio', label: 'Operating Margin', format: formatPercentage },
    ],
    BalanceSheet: [
      { id: 'total_assets', label: 'Total Assets', format: formatCurrency },
      { id: 'total_liabilities', label: 'Total Liabilities', format: formatCurrency },
      { id: 'total_shareholders_equity', label: 'Total Shareholders Equity', format: formatCurrency },
      { id: 'total_liabilities_to_equity_ratio', label: 'Liabilities to Equity Ratio', format: formatRatio },
      { id: 'leverage_ratio', label: 'Leverage Ratio', format: formatRatio },
      { id: 'liquidity_ratio', label: 'Liquidity Ratio', format: formatRatio },
    ],
    CashFlow: [
      { id: 'operating_cash_flow', label: 'Operating Cash Flow', format: formatCurrency },
      { id: 'investing_cash_flow', label: 'Investing Cash Flow', format: formatCurrency },
      { id: 'financing_cash_flow', label: 'Financing Cash Flow', format: formatCurrency },
      { id: 'cfo_margin', label: 'CFO Margin', format: formatPercentage },
      { id: 'cfi_margin', label: 'CFI Margin', format: formatPercentage },
      { id: 'cff_margin', label: 'CFF Margin', format: formatPercentage },
    ],
  };

  const renderDetailContent = (report) => (
    <Box sx={{ 
      display: 'grid', 
      gridTemplateColumns: 'repeat(3, 1fr)', 
      gap: 2,
      py: 2 
    }}>
      {Object.entries(detailGroups).map(([group, metrics]) => (
        <DetailCard key={group}>
          <Typography variant="subtitle1">
            {group}
          </Typography>
          <Table size="small">
            <TableBody>
              {metrics.map((metric) => (
                <TableRow key={metric.id}>
                  <TableCell component="th" scope="row" sx={{ border: 'none' }}>
                    {metric.label}
                  </TableCell>
                  <TableCell align="right" sx={{ border: 'none' }}>
                    {metric.format(report[metric.id])}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DetailCard>
      ))}
    </Box>
  );

  const handleEditReport = (reportId) => {
    setEditingReportId(reportId);
  };

  const handleSaveReport = (updatedReport) => {
    setReports(prevReports => 
      prevReports.map(report => 
        report.id === updatedReport.id ? updatedReport : report
      )
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box sx={{ mt: 4 }}>
        <Typography variant="h4" component="h2" gutterBottom>
          Financial Reports
        </Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mb: 4, alignItems: 'center' }}>
          <Autocomplete
            options={symbols}
            value={selectedSymbol}
            onChange={handleSymbolChange}
            renderInput={(params) => <TextField {...params} label="Select Symbol" />}
            sx={{ minWidth: 200 }}
          />
          <TextField
            select
            label="Year"
            value={selectedYear}
            onChange={handleYearChange}
            sx={{ minWidth: 120 }}
          >
            {[2020, 2021, 2022, 2023, 2024].map((year) => (
              <MenuItem key={year} value={year}>{year}</MenuItem>
            ))}
          </TextField>
          <TextField
            select
            label="Quarter"
            value={selectedQuarter}
            onChange={handleQuarterChange}
            sx={{ minWidth: 120 }}
          >
            {[1, 2, 3, 4].map((quarter) => (
              <MenuItem key={quarter} value={quarter}>Q{quarter}</MenuItem>
            ))}
          </TextField>
          <Button variant="contained" onClick={handleFilter}>Filter</Button>
        </Box>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox" />
                {summaryColumns.map((column) => (
                  <TableCell key={column.id}>{column.label}</TableCell>
                ))}
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reports.map((report) => (
                <ExpandableTableRow
                  key={report.id}
                  hover
                  expandContent={renderDetailContent(report)}
                >
                  {summaryColumns.map((column) => (
                    <TableCell key={column.id}>
                      {column.format(report[column.id])}
                    </TableCell>
                  ))}
                  <TableCell align="right">
                    <Button 
                      variant="outlined" 
                      size="small" 
                      onClick={() => handleEditReport(report.id)}
                    >
                      Edit
                    </Button>
                  </TableCell>
                </ExpandableTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={totalReports}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 25, 50]}
        />
        <ReportEditor 
          reportId={editingReportId}
          open={Boolean(editingReportId)}
          onClose={() => setEditingReportId(null)}
          onSave={handleSaveReport}
        />
      </Box>
    </LocalizationProvider>
  );
};

export default ReportFilter;