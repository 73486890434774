import React from 'react';
import { Paper, Typography, Box } from '@mui/material';

const ArticlesSummaryPanel = ({ summary }) => {
  return (
    <Paper elevation={3} sx={{ p: 2, height: '100%' }}>
      <Typography variant="h6" gutterBottom>Articles Summary</Typography>
      <Box mb={2}>
        <Typography variant="subtitle2">Total Articles:</Typography>
        <Typography>{summary.total_articles_number}</Typography>
      </Box>
      <Box mb={2}>
        <Typography variant="subtitle2">Categorization:</Typography>
        <Typography>Categorized: {summary.total_categorized}</Typography>
        <Typography>Uncategorized: {summary.total_uncategorized}</Typography>
      </Box>
      <Box mb={2}>
        <Typography variant="subtitle2">Category Sentiment Breakdown:</Typography>
        <Typography>Positive: {summary.total_category_positive}</Typography>
        <Typography>Negative: {summary.total_category_negative}</Typography>
        <Typography>Neutral: {summary.total_category_neutral}</Typography>
      </Box>
      <Box mb={2}>
        <Typography variant="subtitle2">AI Category Sentiment Breakdown:</Typography>
        <Typography>Positive: {summary.total_ai_category_positive}</Typography>
        <Typography>Negative: {summary.total_ai_category_negative}</Typography>
        <Typography>Neutral: {summary.total_ai_category_neutral}</Typography>
      </Box>
      <Box mb={2}>
         <Typography variant="subtitle2">Advanced Sentiment Analysis Breakdown:</Typography>
          <Typography>Positive: {summary.total_advanced_positive}</Typography>
          <Typography>Negative: {summary.total_advanced_negative}</Typography>
          <Typography>Neutral: {summary.total_advanced_neutral}</Typography>
      </Box>
      <Box mb={2}>
        <Typography variant="subtitle2">Average Sentiments:</Typography>
        <Typography>Self Category Sentiment Average: {summary.average_category_sentiment.toFixed(2)}</Typography>
        <Typography>AI Category Sentiment Average: {summary.average_ai_category_sentiment.toFixed(2)}</Typography>
        <Typography>Advanced (non category) Sentiment Average: {summary.average_advanced_sentiment.toFixed(2)}</Typography>
      </Box>
    </Paper>
  );
};

export default ArticlesSummaryPanel;