import React, { useState, useEffect, useCallback } from 'react';
import { 
  Typography, Box, TextField, Button, Select, MenuItem, 
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  TablePagination, Link, Tooltip, Grid, Autocomplete
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { format, parseISO, differenceInDays } from 'date-fns';
import { filterArticles, getSourceTypes, getSymbols, exportArticlesToCSV, getArticlesSentimentAverages } from '../api';
import ArticlesSentimentAveragesPanel from './ArticlesSentimentAveragesPanel';
import ArticlesSummaryPanel from './ArticlesSummaryPanel';

const ArticleFilter = () => {
  const [symbols, setSymbols] = useState([]);
  const [selectedSymbol, setSelectedSymbol] = useState(null);
  const [sourceTypes, setSourceTypes] = useState([]);
  const [selectedSourceType, setSelectedSourceType] = useState('All');
  const [articles, setArticles] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [totalArticles, setTotalArticles] = useState(0);
  const [page, setPage] = useState(0);  // Material-UI uses 0-indexed pages
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isExporting, setIsExporting] = useState(false);
  const [resultsSummary, setResultsSummary] = useState(null);
  const [selectedDays, setSelectedDays] = useState(0);
  const [sortBy, setSortBy] = useState('publish_date');
  const [sortOrder, setSortOrder] = useState('desc');
  const [sentimentAverages, setSentimentAverages] = useState(null);

  const fetchSentimentAverages = useCallback(async () => {
    if (selectedSymbol) {
      try {
        const averages = await getArticlesSentimentAverages(selectedSymbol);
        setSentimentAverages(averages);
      } catch (error) {
        console.error('Error fetching sentiment averages:', error);
      }
    }
  }, [selectedSymbol]);

  useEffect(() => {
    fetchSentimentAverages();
  }, [fetchSentimentAverages]);


  useEffect(() => {
    fetchSymbols();
  }, []);

  const fetchSymbols = async () => {
    try {
      const data = await getSymbols();
      setSymbols(data);
    } catch (error) {
      console.error('Error fetching symbols:', error);
    }
  };

  const fetchSourceTypes = useCallback(async () => {
    if (selectedSymbol) {
      try {
        const data = await getSourceTypes(selectedSymbol);
        setSourceTypes(['All', ...data]);
      } catch (error) {
        console.error('Error fetching source types:', error);
      }
    }
  }, [selectedSymbol]);

  useEffect(() => {
    fetchSourceTypes();
  }, [fetchSourceTypes]);


  const handleExportToCSV = async () => {
    setIsExporting(true);
    try {
      const formattedStartDate = startDate ? format(startDate, 'yyyy-MM-dd') : null;
      const formattedEndDate = endDate ? format(endDate, 'yyyy-MM-dd') : null;
      await exportArticlesToCSV({
        symbol: selectedSymbol,
        source: selectedSourceType === 'All' ? null : selectedSourceType,
        start_date: formattedStartDate,
        end_date: formattedEndDate
      });
    } catch (error) {
      console.error('Error exporting CSV:', error);
      // Optionally, show an error message to the user
    } finally {
      setIsExporting(false);
    }
  };

  const fetchArticles = useCallback(async () => {
    if (!selectedSymbol) return;

    try {
      const formattedStartDate = startDate ? format(startDate, 'yyyy-MM-dd') : null;
      const formattedEndDate = endDate ? format(endDate, 'yyyy-MM-dd') : null;
      const response = await filterArticles({ 
        symbol: selectedSymbol, 
        start_date: formattedStartDate, 
        end_date: formattedEndDate,
        source: selectedSourceType === 'All' ? null : selectedSourceType,
        page: page + 1,
        page_size: rowsPerPage,
        sort_by: sortBy,
        sort_order: sortOrder
      });
      setArticles(response.items || []);
      setTotalArticles(response.total || 0);
      setResultsSummary(response.results_summary || null);
    } catch (error) {
      console.error('Error fetching articles:', error);
    }
  }, [selectedSymbol, startDate, endDate, selectedSourceType, page, rowsPerPage, sortBy, sortOrder]);


  useEffect(() => {
    fetchArticles();
  }, [fetchArticles]);
  
  const handleSortChange = (event) => {
    const [newSortBy, newSortOrder] = event.target.value.split(':');
    setSortBy(newSortBy);
    setSortOrder(newSortOrder);
    setPage(0);
  };

  const handleSymbolChange = (event, newValue) => {
    setSelectedSymbol(newValue);
    setSelectedSourceType('All');
    // Reset page when symbol changes
    setPage(0);
  };

  const handleSourceTypeChange = (event) => {
    setSelectedSourceType(event.target.value);
  };

  const handleFilter = () => {
    setPage(0);
    fetchArticles();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const updateSelectedDays = useCallback(() => {
    if (startDate && endDate) {
      const days = differenceInDays(endDate, startDate) + 1; // +1 to include both start and end dates
      setSelectedDays(days);
    } else {
      setSelectedDays(0);
    }
  }, [startDate, endDate]);

  useEffect(() => {
    updateSelectedDays();
  }, [startDate, endDate, updateSelectedDays]);


  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box sx={{ mt: 4 }}>
        <Typography variant="h4" component="h2" gutterBottom>
          Article Filter
        </Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mb: 4, alignItems: 'center' }}>
          <Autocomplete
            options={symbols}
            value={selectedSymbol}
            onChange={handleSymbolChange}
            renderInput={(params) => <TextField {...params} label="Select Symbol" />}
            sx={{ minWidth: 200 }}
          />
          <Select
            value={selectedSourceType}
            onChange={handleSourceTypeChange}
            displayEmpty
            sx={{ minWidth: 120 }}
            disabled={!selectedSymbol}
          >
            <MenuItem value="" disabled>Select source type</MenuItem>
            {sourceTypes.map((sourceType) => (
              <MenuItem key={sourceType} value={sourceType}>{sourceType}</MenuItem>
            ))}
          </Select>
          <DatePicker
            label="Start Date"
            value={startDate}
            onChange={(newValue) => setStartDate(newValue)}
            renderInput={(params) => <TextField {...params} />}
          />
          <DatePicker
            label="End Date"
            value={endDate}
            onChange={(newValue) => setEndDate(newValue)}
            renderInput={(params) => <TextField {...params} />}
          />
          <Typography variant="body1">
            Selected Days: {selectedDays}
          </Typography>
          <Select
            value={`${sortBy}:${sortOrder}`}
            onChange={handleSortChange}
            displayEmpty
            sx={{ minWidth: 200 }}
          >
            <MenuItem value="publish_date:desc">Date (Newest First)</MenuItem>
            <MenuItem value="publish_date:asc">Date (Oldest First)</MenuItem>
          </Select>
          <Button variant="contained" onClick={handleFilter}>Filter</Button>
          <Button 
            variant="contained" 
            color="secondary" 
            onClick={handleExportToCSV} 
            disabled={isExporting || !selectedSymbol}
          >
            {isExporting ? 'Exporting...' : 'Export to CSV'}
          </Button>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <TableContainer component={Paper} sx={{ height: 600, width: '100%', mb: 2 }}>
              <Table stickyHeader sx={{ minWidth: 1200 }}>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold', minWidth: 100 }}>Date</TableCell>
                <TableCell sx={{ fontWeight: 'bold', minWidth: 200 }}>Title</TableCell>
                <TableCell sx={{ fontWeight: 'bold', minWidth: 80 }}>Symbol</TableCell>
                <TableCell sx={{ fontWeight: 'bold', minWidth: 120 }}>Source Type</TableCell>
                <TableCell sx={{ fontWeight: 'bold', minWidth: 100 }}>Link</TableCell>
                <TableCell sx={{ fontWeight: 'bold', minWidth: 200 }}>Summary</TableCell>
                <TableCell sx={{ fontWeight: 'bold', minWidth: 120 }}>Category</TableCell>
                <TableCell sx={{ fontWeight: 'bold', minWidth: 120 }}>Title Category?</TableCell>
                <TableCell sx={{ fontWeight: 'bold', minWidth: 120 }}>AI Category</TableCell>
                <TableCell sx={{ fontWeight: 'bold', minWidth: 120 }}>AI Category Confidence</TableCell>
                {/* <TableCell sx={{ fontWeight: 'bold', minWidth: 120 }}>Hybrid Category</TableCell> */}
                <TableCell sx={{ fontWeight: 'bold', minWidth: 120 }}>Category Sentiment</TableCell>
                {/* <TableCell sx={{ fontWeight: 'bold', minWidth: 120 }}>AI Sentiment</TableCell> */}
                <TableCell sx={{ fontWeight: 'bold', minWidth: 120 }}>AI Category Sentiment</TableCell>
                {/* <TableCell sx={{ fontWeight: 'bold', minWidth: 120 }}>Hybrid Category Sentiment</TableCell> */}
                <TableCell sx={{ fontWeight: 'bold', minWidth: 120 }}>AI Advanced Sentiment</TableCell>
                <TableCell sx={{ fontWeight: 'bold', minWidth: 200 }}>AI Summary</TableCell>
                <TableCell sx={{ fontWeight: 'bold', minWidth: 200 }}>AI Explanation</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {articles.map((article) => (
                <TableRow key={article.id} hover>
                  <TableCell>{format(parseISO(article.publish_date), 'MM/dd/yyyy')}</TableCell>
                  <TableCell>
                    <Tooltip title={article.title} arrow>
                      <Typography noWrap sx={{ maxWidth: 200 }}>
                        {article.title}
                      </Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell>{article.symbol}</TableCell>
                  <TableCell>{article.source}</TableCell>
                  <TableCell>
                    <Link href={article.link} target="_blank" rel="noopener noreferrer">
                      Article Link
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Tooltip title={article.summary} arrow>
                      <Typography noWrap sx={{ maxWidth: 200 }}>
                        {article.summary}
                      </Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell>{article.category_name}</TableCell>
                  <TableCell>{article.is_title_category ? 'Yes' : 'No'}</TableCell>
                  <TableCell>{article.ai_category_name}</TableCell>
                  <TableCell>{article.ai_category_confidence}</TableCell>
                  {/* <TableCell>{article.hybrid_category_name}</TableCell> */}
                  <TableCell>{article.category_sentiment !== null ? article.category_sentiment.toFixed(2) : 'N/A'}</TableCell>
                  {/* <TableCell>{article.ai_sentiment !== null ? article.ai_sentiment.toFixed(2) : 'N/A'}</TableCell> */}
                  <TableCell>{article.ai_category_sentiment !== null ? article.ai_category_sentiment.toFixed(2) : 'N/A'}</TableCell>
                  {/* <TableCell>{article.hybrid_category_sentiment !== null ? article.hybrid_category_sentiment.toFixed(2) : 'N/A'}</TableCell> */}
                  <TableCell>{article.ai_advanced_sentiment !== null ? article.ai_advanced_sentiment.toFixed(2) : 'N/A'}</TableCell>
                  <TableCell>
                  <Tooltip title={article.ai_summary} arrow>
                    <Typography noWrap sx={{ maxWidth: 200 }}>
                      {article.ai_summary}
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Tooltip title={article.ai_explanation} arrow>
                    <Typography noWrap sx={{ maxWidth: 200 }}>
                      {article.ai_explanation}
                    </Typography>
                  </Tooltip>
                </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
            <TablePagination
              component="div"
              count={totalArticles}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[5, 10, 25, 50]}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            {resultsSummary && (
            <Box sx={{ position: 'sticky', top: 16, width: '100%' }}>
                <ArticlesSummaryPanel summary={resultsSummary} />
                <ArticlesSentimentAveragesPanel averages={sentimentAverages} />
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
    </LocalizationProvider>
  );
};

export default ArticleFilter;