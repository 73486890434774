import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, IconButton, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import { Menu, X, Home, Plus, Filter, Brain, FolderOpen, AlertCircle, PieChart, Upload, DollarSign, FileSearch2, FilePlus } from 'lucide-react';

const drawerWidth = 240;

const StyledDrawer = styled(Drawer)(({ theme, open }) => ({
  width: open ? drawerWidth : theme.spacing(7),
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  '& .MuiDrawer-paper': {
    backgroundColor: '#2563eb', // blue-600
    color: 'white',
    width: open ? drawerWidth : theme.spacing(7),
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  },
}));

const menuItems = [
  { icon: Home, label: 'Home', path: '/' },
  { icon: Plus, label: 'Add Ticker', path: '/add-ticker' },
  { icon: Filter, label: 'Article Filter', path: '/article-filter' },
  { icon: Brain, label: 'AI Analysis', path: '/ai-analysis' },
  { icon: FolderOpen, label: 'Categories Management', path: '/categories-management' },
  { icon: AlertCircle, label: 'Low Confidence Articles', path: '/low-confidence-articles' },
  { icon: PieChart, label: 'Articles Per Category', path: '/articles-per-category' },
  { icon: Upload, label: 'Upload Report', path: '/upload-report' },
  { icon: FileSearch2, label: 'Report Filter', path: '/report-filter' },
  { icon: DollarSign, label: 'Financial Reports', path: '/financial-reports' },
  { icon: FilePlus, label: 'Manual Report Entry', path: '/manual-report-entry' },
];

const SideBar = () => {
  const [open, setOpen] = useState(false);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  return (
    <StyledDrawer variant="permanent" open={open}>
      <IconButton onClick={handleDrawerToggle} sx={{ color: 'white', p: 2 }}>
        {open ? <X size={24} /> : <Menu size={24} />}
      </IconButton>
      <List>
        {menuItems.map((item, index) => (
          <ListItem
            button
            key={item.label}
            component={Link}
            to={item.path}
            sx={{
              display: 'block',
              '&:hover': {
                backgroundColor: '#1d4ed8', // blue-700 for hover effect
              },
            }}
          >
            <Tooltip title={open ? '' : item.label} placement="right" arrow>
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                  color: 'white',
                }}
              >
                <item.icon size={24} />
              </ListItemIcon>
            </Tooltip>
            {open && <ListItemText primary={item.label} sx={{ opacity: 1 }} />}
          </ListItem>
        ))}
      </List>
    </StyledDrawer>
  );
};

export default SideBar;