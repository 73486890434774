import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, Outlet } from 'react-router-dom';
import { Box, CssBaseline } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { AuthProvider } from './contexts/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';
import Login from './components/Login';

// Your existing imports
import NavBar from './components/NavBar';
import SideBar from './components/SideBar';
import Home from './components/Home';
import AddTicker from './components/AddTicker';
import ArticleFilter from './components/ArticleFilter';
import AIAnalysis from './components/AIAnalysis';
import CategoriesManagement from './components/CategoriesManagement';
import LowConfidenceArticles from './components/LowConfidenceArticles';
import ArticlesPerCategory from './components/ArticlesPerCategory';
import QuarterlyReportUpload from './components/QuarterlyReportUpload';
import ReportFilter from './components/ReportFilter';
import FinancialReportPage from './components/FinancialReportPage';
import ManualReportEntryPage from './components/ManualReportEntryPage';

// Layout component for authenticated pages
const AuthenticatedLayout = () => {
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <NavBar />
      <SideBar />
      <Box component="main" sx={{ flexGrow: 1, p: 3, mt: 8 }}>
        <Outlet />
      </Box>
    </Box>
  );
};

function App() {
  return (
    <AuthProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Router>
          <Routes>
            {/* Public route */}
            <Route path="/login" element={<Login />} />

            {/* Protected routes */}
            <Route
              element={
                <ProtectedRoute>
                  <AuthenticatedLayout />
                </ProtectedRoute>
              }
            >
              <Route path="/" element={<Home />} />
              <Route path="/add-ticker" element={<AddTicker />} />
              <Route path="/article-filter" element={<ArticleFilter />} />
              <Route path="/ai-analysis" element={<AIAnalysis />} />
              <Route path="/categories-management" element={<CategoriesManagement />} />
              <Route path="/low-confidence-articles" element={<LowConfidenceArticles />} />
              <Route path="/articles-per-category" element={<ArticlesPerCategory />} />
              <Route path="/upload-report" element={<QuarterlyReportUpload />} />
              <Route path="/report-filter" element={<ReportFilter />} />
              <Route path="/financial-reports" element={<FinancialReportPage />} />
              <Route path="/manual-report-entry" element={<ManualReportEntryPage />} />
            </Route>

            {/* Catch all route */}
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Router>
      </LocalizationProvider>
    </AuthProvider>
  );
}

export default App;