import React, { useState, useEffect, useCallback } from 'react';
import { 
    Typography, Box, Table, TableBody, TableCell, 
    TableContainer, TableHead, TableRow, Paper, TablePagination, 
    Link, Tooltip, CircularProgress, Button, Snackbar, Alert, Select, MenuItem, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions
  } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { format, parseISO } from 'date-fns';
import { getLowConfidenceArticles, recategorizeArticles, aiRecategorizeArticles, getCategories, updateArticleCategory,updateArticleAICategory, deleteArticle } from '../api';


const LowConfidenceArticles = () => {
  const [articles, setArticles] = useState([]);
  const [totalArticles, setTotalArticles] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [isRecategorizing, setIsRecategorizing] = useState(false);
  const [isAiRecategorizing, setIsAiRecategorizing] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [categories, setCategories] = useState([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [articleToDelete, setArticleToDelete] = useState(null);

  
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await getCategories();
        setCategories(response);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };
  
    fetchCategories();
  }, []);

  const fetchLowConfidenceArticles = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getLowConfidenceArticles({
        page: page + 1,
        page_size: rowsPerPage,
      });
      setArticles(response.items || []);
      setTotalArticles(response.total || 0);
    } catch (error) {
      console.error('Error fetching low confidence articles:', error);
      setSnackbar({ open: true, message: 'Failed to load articles. Please try again.', severity: 'error' });
    } finally {
      setIsLoading(false);
    }
  }, [page, rowsPerPage]);
  

  useEffect(() => {
    fetchLowConfidenceArticles();
  }, [fetchLowConfidenceArticles]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  const handleRecategorize = async () => {
    setIsRecategorizing(true);
    try {
      const response = await recategorizeArticles();
      setSnackbar({ open: true, message: response.message, severity: 'success' });
      fetchLowConfidenceArticles(); // Refresh the list after recategorization
    } catch (error) {
      setSnackbar({ open: true, message: 'Error recategorizing articles', severity: 'error' });
    } finally {
      setIsRecategorizing(false);
    }
  };

  const handleAiRecategorize = async () => {
    setIsAiRecategorizing(true);
    try {
      const response = await aiRecategorizeArticles();
      setSnackbar({ open: true, message: response.message, severity: 'success' });
      fetchLowConfidenceArticles(); // Refresh the list after recategorization
    } catch (error) {
      setSnackbar({ open: true, message: 'Error AI recategorizing articles', severity: 'error' });
    } finally {
      setIsAiRecategorizing(false);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const handleCategoryChange = async (articleId, newCategoryId) => {
    // Get the previous category ID and name
    const prevArticle = articles.find((article) => article.id === articleId);
    const prevCategoryId = prevArticle.category_id;
    const prevCategoryName = prevArticle.category_name;
  
    // Update the article's category in the local state optimistically
    setArticles((prevArticles) =>
      prevArticles.map((article) =>
        article.id === articleId
          ? {
              ...article,
              category_id: newCategoryId,
              category_name: categories.find((cat) => cat.id === newCategoryId)?.name,
            }
          : article
      )
    );

    try {
      await updateArticleCategory({
          article_id: articleId,
          category_id: newCategoryId});
      setSnackbar({ open: true, message: 'Category updated successfully', severity: 'success' });
    } catch (error) {
      console.error('Error updating category:', error);
      setSnackbar({ open: true, message: 'Error updating category. Please try again.', severity: 'error' });

  
      // Revert the change in the local state
      setArticles((prevArticles) =>
        prevArticles.map((article) =>
          article.id === articleId
            ? {
                ...article,
                category_id: prevCategoryId,
                category_name: prevCategoryName,
              }
            : article
        )
      );
    }
  };

  const handleAICategoryChange = async (articleId, newAICategoryId) => {
    // Get the previous AI category ID and name
    const prevArticle = articles.find((article) => article.id === articleId);
    const prevAICategoryId = prevArticle.ai_category_id;
    const prevAICategoryName = prevArticle.ai_category_name;
  
    // Update the article's AI category in the local state optimistically
    setArticles((prevArticles) =>
      prevArticles.map((article) =>
        article.id === articleId
          ? {
              ...article,
              ai_category_id: newAICategoryId,
              ai_category_name: categories.find((cat) => cat.id === newAICategoryId)?.name,
            }
          : article
      )
    );

    try {
      await updateArticleAICategory({
          article_id: articleId,
          ai_category_id: newAICategoryId});
      setSnackbar({ open: true, message: 'AI Category updated successfully', severity: 'success' });
    } catch (error) {
      console.error('Error updating AI category:', error);
      setSnackbar({ open: true, message: 'Error updating AI category. Please try again.', severity: 'error' });


      // Revert the change in the local state
      setArticles((prevArticles) =>
        prevArticles.map((article) =>
          article.id === articleId
            ? {
                ...article,
                ai_category_id: prevAICategoryId,
                ai_category_name: prevAICategoryName,
              }
            : article
        )
      );
    }
  };
  
  const handleDeleteArticle = async () => {
    // Close the dialog immediately
    setDeleteDialogOpen(false);
  
    // Remove the article from the local state optimistically
    setArticles((prevArticles) => prevArticles.filter((article) => article.id !== articleToDelete.id));
  
    try {
      await deleteArticle(articleToDelete.id);
      setSnackbar({ open: true, message: 'Article deleted successfully', severity: 'success' });
    } catch (error) {
      console.error('Error deleting article:', error);
      setSnackbar({ open: true, message: 'Error deleting article', severity: 'error' });
  
      // Re-add the article to the local state
      setArticles((prevArticles) => [...prevArticles, articleToDelete]);
    } finally {
      setArticleToDelete(null);
    }
  };
  
  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h4" component="h2" gutterBottom>
        Low Confidence Articles
      </Typography>
      
      <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
        <Button 
          variant="contained" 
          onClick={handleRecategorize} 
          disabled={isRecategorizing || isLoading}
        >
          {isRecategorizing ? 'Recategorizing...' : 'Recategorize Articles'}
        </Button>
        <Button 
          variant="contained" 
          onClick={handleAiRecategorize} 
          disabled={isAiRecategorizing || isLoading}
        >
          {isAiRecategorizing ? 'AI Recategorizing...' : 'AI Recategorize Articles'}
        </Button>
      </Box>

      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <TableContainer component={Paper} sx={{ mt: 2 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Symbol</TableCell>
                  <TableCell>Category</TableCell>
                  <TableCell>Title Category?</TableCell>
                  <TableCell>AI Category</TableCell>
                  <TableCell>AI Category Sentiment</TableCell>
                  <TableCell>Confidence</TableCell>
                  <TableCell>AI Summary</TableCell>
                  <TableCell>AI Explanation</TableCell>
                  <TableCell>Link</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {articles.map((article) => (
                  <TableRow key={article.id} hover>
                    <TableCell>{format(parseISO(article.publish_date), 'MM/dd/yyyy')}</TableCell>
                    <TableCell>
                      <Tooltip title={article.title} arrow>
                        <Typography noWrap sx={{ maxWidth: 200 }}>
                          {article.title}
                        </Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell>{article.symbol}</TableCell>
                    <TableCell>
                    <Select
                      value={article.category_id || ''}
                      onChange={(e) => handleCategoryChange(article.id, e.target.value)}
                      displayEmpty
                      sx={{ minWidth: 150 }}
                    >
                      {categories.map((category) => (
                        <MenuItem key={category.id} value={category.id}>
                          {category.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                    <TableCell>{article.title_category ? 'Yes' : 'No'}</TableCell>
                    <TableCell>
                    <Select
                      value={article.ai_category_id || ''}
                      onChange={(e) => handleAICategoryChange(article.id, e.target.value)}
                      displayEmpty
                      sx={{ minWidth: 150 }}
                    >
                      {categories.map((category) => (
                        <MenuItem key={category.id} value={category.id}>
                          {category.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                    <TableCell>{article.ai_category_sentiment.toFixed(2)}</TableCell>
                    <TableCell>{article.ai_category_confidence.toFixed(2)}</TableCell>
                    <TableCell>
                      <Tooltip title={article.ai_summary} arrow>
                        <Typography noWrap sx={{ maxWidth: 200 }}>
                          {article.ai_summary}
                        </Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <Tooltip title={article.ai_explanation} arrow>
                        <Typography noWrap sx={{ maxWidth: 200 }}>
                          {article.ai_explanation}
                        </Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <Link href={article.link} target="_blank" rel="noopener noreferrer">
                        Article Link
                      </Link>
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() => {
                          setArticleToDelete(article);
                          setDeleteDialogOpen(true);
                        }}
                        color="error"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Dialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
              >
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Are you sure you want to delete the article titled "{articleToDelete?.title}"?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
                    Cancel
                  </Button>
                  <Button onClick={handleDeleteArticle} color="error">
                    Delete
                  </Button>
                </DialogActions>
              </Dialog>
          
          <TablePagination
            component="div"
            count={totalArticles}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 25, 50]}
          />
        </>
      )}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default LowConfidenceArticles;