import React, { useState, useEffect, useCallback } from 'react';
import { 
  Typography, Box, TextField, Button, Autocomplete, CircularProgress, Grid
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { 
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, LabelList, Legend
} from 'recharts';
import axios from 'axios';
import { format, differenceInDays } from 'date-fns';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000';

const ArticlesPerCategory = () => {
    const [symbols, setSymbols] = useState([]);
    const [selectedSymbol, setSelectedSymbol] = useState(null);
    const [startDate1, setStartDate1] = useState(null);
    const [endDate1, setEndDate1] = useState(null);
    const [startDate2, setStartDate2] = useState(null);
    const [endDate2, setEndDate2] = useState(null);
    const [chartData, setChartData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedDays1, setSelectedDays1] = useState(0);
    const [selectedDays2, setSelectedDays2] = useState(0);

    useEffect(() => {
      fetchSymbols();
    }, []);

    const updateSelectedDays = useCallback(() => {
      if (startDate1 && endDate1) {
        const days = differenceInDays(endDate1, startDate1) + 1;
        setSelectedDays1(days);
      } else {
        setSelectedDays1(0);
      }

      if (startDate2 && endDate2) {
        const days = differenceInDays(endDate2, startDate2) + 1;
        setSelectedDays2(days);
      } else {
        setSelectedDays2(0);
      }
    }, [startDate1, endDate1, startDate2, endDate2]);

    useEffect(() => {
      updateSelectedDays();
    }, [startDate1, endDate1, startDate2, endDate2, updateSelectedDays]);

    const fetchSymbols = async () => {
      try {
        const response = await axios.get(`${API_URL}/get_symbols`);
        setSymbols(response.data);
      } catch (error) {
        console.error('Error fetching symbols:', error);
      }
    };
  
    const fetchArticlesGroupedByCategory = async () => {
      if (!selectedSymbol || !startDate1 || !endDate1 || !startDate2 || !endDate2) return;
  
      setIsLoading(true);
      try {
        const [response1, response2] = await Promise.all([
          axios.get(`${API_URL}/articles_grouped_by_category`, {
            params: {
              symbol: selectedSymbol,
              start_date: format(startDate1, 'yyyy-MM-dd'),
              end_date: format(endDate1, 'yyyy-MM-dd'),
            }
          }),
          axios.get(`${API_URL}/articles_grouped_by_category`, {
            params: {
              symbol: selectedSymbol,
              start_date: format(startDate2, 'yyyy-MM-dd'),
              end_date: format(endDate2, 'yyyy-MM-dd'),
            }
          })
        ]);
  
        const combinedData = Object.keys({ ...response1.data, ...response2.data }).map(category => ({
          category,
          count1: response1.data[category] || 0,
          count2: response2.data[category] || 0
        }));
  
        setChartData(combinedData);
      } catch (error) {
        console.error('Error fetching articles grouped by category:', error);
      } finally {
        setIsLoading(false);
      }
    };
  
    const handleSubmit = (event) => {
      event.preventDefault();
      fetchArticlesGroupedByCategory();
    };
  
    const COLORS = ['#8884d8', '#82ca9d'];
  
    const CustomTooltip = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
        return (
          <Box sx={{ 
            backgroundColor: 'rgba(255, 255, 255, 0.8)', 
            border: '1px solid #ccc', 
            p: 2, 
            borderRadius: 1,
            boxShadow: '0px 3px 15px rgba(0,0,0,0.2)'
          }}>
            <Typography variant="subtitle2">{label}</Typography>
            <Typography variant="body2" color={COLORS[0]}>
              {`Range 1: ${payload[0].value}`}
            </Typography>
            <Typography variant="body2" color={COLORS[1]}>
              {`Range 2: ${payload[1].value}`}
            </Typography>
          </Box>
        );
      }
      return null;
    };
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Box sx={{ mt: 4, maxWidth: 1200, mx: 'auto', p: 3, backgroundColor: '#f5f5f5', borderRadius: 2 }}>
          <Typography variant="h5" component="h1" gutterBottom sx={{ fontWeight: 'bold', color: '#333', textAlign: 'center', mb: 3 }}>
            Articles per Category Comparison
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2} alignItems="flex-start">
              <Grid item xs={12} md={3}>
                <Autocomplete
                  options={symbols}
                  value={selectedSymbol}
                  onChange={(event, newValue) => setSelectedSymbol(newValue)}
                  renderInput={(params) => <TextField {...params} label="Select Symbol" required fullWidth />}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="subtitle2" gutterBottom>Date Range 1</Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                  <DatePicker
                    label="Start Date"
                    value={startDate1}
                    onChange={(newValue) => setStartDate1(newValue)}
                    renderInput={(params) => <TextField {...params} required fullWidth />}
                  />
                  <DatePicker
                    label="End Date"
                    value={endDate1}
                    onChange={(newValue) => setEndDate1(newValue)}
                    renderInput={(params) => <TextField {...params} required fullWidth />}
                  />
                  <Typography variant="body2">
                    Selected Days: {selectedDays1}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="subtitle2" gutterBottom>Date Range 2</Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                  <DatePicker
                    label="Start Date"
                    value={startDate2}
                    onChange={(newValue) => setStartDate2(newValue)}
                    renderInput={(params) => <TextField {...params} required fullWidth />}
                  />
                  <DatePicker
                    label="End Date"
                    value={endDate2}
                    onChange={(newValue) => setEndDate2(newValue)}
                    renderInput={(params) => <TextField {...params} required fullWidth />}
                  />
                  <Typography variant="body2">
                    Selected Days: {selectedDays2}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={1} sx={{ display: 'flex', alignItems: 'flex-start', pt: '24px' }}>
                <Button 
                  type="submit" 
                  variant="contained" 
                  disabled={isLoading} 
                  fullWidth
                >
                  {isLoading ? <CircularProgress size={24} /> : 'Compare'}
                </Button>
              </Grid>
            </Grid>
          </form>
        
        {chartData.length > 0 && (
          <Box sx={{ height: 500, mt: 4 }}>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                data={chartData}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 100
                }}
              >
                <CartesianGrid strokeDasharray="3 3" stroke="#ccc" />
                <XAxis 
                  dataKey="category" 
                  tick={{ fill: '#666', fontSize: 12 }}
                  tickLine={{ stroke: '#666' }}
                  interval={0}
                  angle={-45}
                  textAnchor="end"
                  height={80}
                />
                <YAxis 
                  tick={{ fill: '#666', fontSize: 12 }}
                  tickLine={{ stroke: '#666' }}
                />
                <Tooltip content={<CustomTooltip />} />
                <Legend />
                <Bar dataKey="count1" fill={COLORS[0]} name="Range 1">
                  <LabelList dataKey="count1" position="top" fill="#333" fontSize={10} />
                </Bar>
                <Bar dataKey="count2" fill={COLORS[1]} name="Range 2">
                  <LabelList dataKey="count2" position="top" fill="#333" fontSize={10} />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </Box>
        )}
      </Box>
    </LocalizationProvider>
  );
};

export default ArticlesPerCategory;