import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000';
const ARTICLES = API_URL + '/articles';
const REPORTS = API_URL + '/reports';
const AUTH = API_URL + '/auth';

// Create axios instance with default config
const api = axios.create({
  baseURL: API_URL
});

// Add auth interceptor
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      console.log('Token in request:', token); // Debug log
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add response interceptor for handling auth errors
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response?.status === 401) {
      localStorage.removeItem('token');
    }
    return Promise.reject(error);
  }
);

// Auth endpoints
export const login = async (username, password) => {
  const response = await api.post(`${AUTH}/token`, 
    new URLSearchParams({
      username,
      password,
    }),
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }
  );
  return response.data;
};

export const getCurrentUser = async () => {
  const response = await api.get(`${AUTH}/me`);
  return response.data;
};

export const addTicker = async (tickerData) => {
  const response = await api.post(`${ARTICLES}/add_ticker`, tickerData);
  return response.data;
};

export const scrapeArticles = async (tickerData) => {
  const response = await api.post(`${ARTICLES}/scrape_articles`, tickerData);
  return response.data;
};

export const filterArticles = async (articlesData) => {
  const response = await api.get(`${ARTICLES}/filter_articles`, {
    params: {
      ...articlesData,
      page: articlesData.page,
      page_size: articlesData.page_size
    }
  });
  return response.data;
};

export const getSourceTypes = async (symbol) => {
  const response = await api.get(`${ARTICLES}/get_source_types/${symbol}`);
  return response.data;
};

export const getSymbols = async () => {
  const response = await api.get(`${ARTICLES}/get_symbols`);
  return response.data;
};

export const getTickers = async () => {
  const response = await api.get(`${ARTICLES}/get_tickers`);
  return response.data;
};

export const getSectors = async () => {
  const response = await api.get(`${REPORTS}/get_sectors`);
  return response.data;
}

export const exportArticlesToCSV = async (params) => {
  try {
    const response = await api.get(`${ARTICLES}/export_articles_csv`, {
      params,
      responseType: 'blob', // Important for file download
    });
    
    // Create a Blob from the response data
    const blob = new Blob([response.data], { type: 'text/csv' });
    
    // Create a link element and trigger download
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `articles_export_${params.symbol}.csv`;
    link.click();
    
    // Clean up
    window.URL.revokeObjectURL(link.href);
  } catch (error) {
    console.error('Error exporting articles to CSV:', error);
    throw error;
  }
};


export const AdvancedAnalyzeArticles = async (symbol) => {
  try {
    const response = await api.post(`${ARTICLES}/advanced_analyze_articles/${symbol}`);
    return response.data;
  } catch (error) {
    console.error('Error in Advanced AI analysis:', error);
    throw error;
  }
};

export const getCategories = async () => {
  try {
    const response = await api.get(`${ARTICLES}/get_categories`);
    return response.data;
  } catch (error) {
    console.error('Error fetching categories:', error);
    throw error;
  }
};

export const addCategory = async (categoryData) => {
  try {
    const response = await api.post(`${ARTICLES}/add_category`, categoryData);
    return response.data;
  } catch (error) {
    console.error('Error adding category:', error);
    throw error;
  }
};

export const updateCategory = async (categoryData) => {
  try {
    const response = await api.put(`${ARTICLES}/update_category`, categoryData);
    return response.data;
  } catch (error) {
    console.error('Error updating category:', error);
    throw error;
  }
};

export const getLowConfidenceArticles = async (params) => {
  try {
    const response = await api.get(`${ARTICLES}/get_low_confidence_articles`, {params});
    return response.data;
  } catch (error) {
    console.error('Error fetching low confidence articles:', error);
    throw error;
  }
};

export const recategorizeArticles = async () => {
  try {
    const response = await api.post(`${ARTICLES}/recategorize_articles`);
    return response.data;
  } catch (error) {
    console.error('Error recategorizing articles:', error);
    throw error;
  }
};

export const aiRecategorizeArticles = async () => {
  try {
    const response = await api.post(`${ARTICLES}/ai_recategorize_articles`);
    return response.data;
  } catch (error) {
    console.error('Error AI recategorizing articles:', error);
    throw error;
  }
};

export const scrapeAllArticles = async (params) => {
  console.log('Scraping all articles:', params);
  try {
  await api.post(`${ARTICLES}/scrape_all_articles`, params);
  }
  catch (error) {
    console.error('Error AI scraping all articles:', error);
    throw error;
  }
};

export const parsePDF = async (formData) => {
  try {
    const response = await api.post(`${ARTICLES}/parse_pdf`, formData,{
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error parsing PDF:', error);
    throw error;
  }
};

export const filterReports = async (reportsData) => {
  const response = await api.get(`${REPORTS}/get_reports`, {
    params: {
      ...reportsData,
      page: reportsData.page,
      page_size: reportsData.page_size
    }
  });
  return response.data;
};


export const getReportById = async (reportId) => {
  const response = await api.get(`${REPORTS}/get_report/${reportId}`);
  return response.data;
};

export const updateReport = async (reportData) => {
  const response = await api.post(`${REPORTS}/update_report`, reportData);
  return response.data;
};

export const insertReportManualy = async (report) => {
  const response = await api.post(`${REPORTS}/insert_report_manualy`, report);
  return response.data;
}

export const getArticlesSentimentAverages = async (symbol) => {
  try {
    const response = await api.get(`${ARTICLES}/get_articles_sentiment_averages/${symbol}`);
    return response.data;
  } catch (error) {
    console.error('Error adding category:', error);
    throw error;
  }
};

export const updateArticleCategory = async (data) => {
  try {
    const response = await api.post(`${ARTICLES}/update_article_category`, {...data});
    return response.data;
  } catch (error) {
    console.error('Error updating article category:', error);
    throw error;
  }
};

export const updateArticleAICategory = async (data) => {
  try {
    const response = await api.post(`${ARTICLES}/update_article_ai_category`, {...data});
    return response.data;
  } catch (error) {
    console.error('Error updating article AI category:', error);
    throw error;
  }
}

export const deleteArticle = async (articleId) => {
  try {
    const response = await api.delete(`${ARTICLES}/delete_article/${articleId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting article:', error);
    throw error;
  }
};
